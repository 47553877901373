<!-- Vue component -->
<template>
   <div>
      <!--      :limit-text="limitText"
         :limit="10"
            :loading="loading"-->
      <multiselect
         :id="id"
         v-model="selectedValues"
         :close-on-select="closeOnSelect"
         :hide-selected="hideSelected"
         :internal-search="internalSearch"
         :max-height="maxHeight"
         :multiple="multiple"
         :options="options"
         :searchable="searchable"
         :show-no-results="showNoResult"
         :show-labels="showLabels"
         :option-height="optionHeight"
         :preselect-first="preselectFirst"
         :label="label"
         :loading="loading"
         open-direction="bottom"
         :placeholder="placeholder"
         :select-label="selectLabel"
         :deselect-label="deselectLabel"
         track-by="id"
         :disabled="disabled"
         @open="onOpen"
         @search-change="onSearch"
         @select="(selectedOption, optionId) => $emit('on-option-select', {selectedOption, optionId})"
      >
         <template slot="afterList">
            <div v-if="loadMore" v-observe-visibility="reachedEndOfList"></div>
            <div class="row text-center" v-if="loadMore"><span><Loader :processing="loading"/>...</span></div>
         </template>

         <template #option="props">
            <slot name="optionSlot" :option="props.option" :search="props.search"></slot>
         </template>

      </multiselect>
   </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import Loader from "@/components/general/Loader.vue";

    // register globally

    export default {
        // OR register locally
        name: "VueMultiselect",

        emits: ['on-open', 'on-reaching-end', 'on-search', 'on-option-select'],

        props: {
            id: {
                type: [String, Number],
                default: 'ajax'
            },
            maxHeight: {
                type: Number,
                default: 800
            },

            closeOnSelect: {
                type: [String, Boolean],
                default: true
            },
            hideSelected: {
                type: [String, Boolean],
                default: false
            },
            internalSearch: {
                type: [String, Boolean],
                default: false
            },

            placeholder: {
                type: String,
                default: 'Click to select'
            },
            optionHeight: {
                type: Number,
                default: 40
            },
            searchable: {
                type: Boolean,
                default: true,
            },

            loadMore: {
                type: [Boolean, String],
                default: false,
            },
            loading: {
                type: [Boolean, String],
                default: false,
            },

            value: {
                type: [Array, Object],
                default: () => []
            },

            options: {
                type: Array,
                default: () => []
            },

            multiple: {
                type: [Boolean, String],
                default: false,
            },

            preselectFirst: {
                type: Boolean,
                default: false,
            },

            selectLabel: {
                type: String,
                default: '',
            },

            deselectLabel: {
                type: String,
                default: '',
            },

            showLabels: {
                type: Boolean,
                default: true,
            },
            label: {
                type: String,
                default: 'name',
            },
            showNoResult: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },

        },

        components: {Loader, Multiselect},

        data() {
            return {
                // topics: [],

                selectedValues: [],
            }
        },

        watch: {
            value: {
                handler(value) {
                    if (value) {
                        this.selectedValues = value;
                    }
                },
                deep: true,
                immediate: true,
            },

            selectedValues: {
                handler(value) {
                    this.$emit('input', value);
                },
                deep: true,
            },

        },

        methods: {
            onSearch(query) {
                this.$emit('on-search', query);
            },

            reachedEndOfList(reached) {
                if (reached) {
                    this.$emit('on-reaching-end');
                }
            },

            onOpen() {
                this.$emit('on-open');
            },
        },
    }
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

