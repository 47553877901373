<!-- @format -->

<script>
import Layout from "@/views/layouts/static/main";
import EmployeeLayout from "@/views/pages/employee/layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import vue2Dropzone from "vue2-dropzone";
import { required, maxLength } from "vuelidate/lib/validators";
import VueMultiselect from "@/components/general/vue-multiselect";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
/**
 * Add-product component
 */

export default {
  components: {
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    VueMultiselect,
    EmployeeLayout,
  },
  page: {
    title: "Add Ticket",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      currentLayout: "Layout",
      title: "Add Ticket",
      items: [
        {
          text: "Tickets",
          href: "/",
        },
        {
          text: "Add Ticket",
          active: true,
        },
      ],
      processing: false,
      ticket: {
        topic: null,
        detail: null,
        status: null,
        priority: null,
        attachment: null,
      },
      user: {},
      referenceTicket : false,
      users: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      // topics : [],
      dropzoneOptions: {
        url: this.$axios.defaults.baseURL + "/ticket/store-attachment",
        thumbnailWidth: 150,
        maxFilesize: 2,
        headers: {
          "My-Awesome-Header": "header value",
        },
        addRemoveLinks: true,
      },
    };
  },

  validations: {
    ticket: {
      topic: { required, maxLength: maxLength(50) },
      detail: { required },
      priority: { required },
    },
  },
  mounted() {
    // this.getFeedbackTopics();
    if (this.isTeamMember()) {
      this.currentLayout = "EmployeeLayout";
    }
  },
  computed: {
    loadMoreUsers() {
      return !!this.user.nextPageUrl;
    },
  },
  methods: {
    // getFeedbackTopics()
    // {
    //     this.$axios.get('get-static-data' , {params: {types: JSON.stringify(["feedbackTopics"]) }}).then((response) => {
    //         let data = response.data.payload.data;
    //         this.topics = data.feedbackTopics;
    //     }).catch((error) => {
    //     this.handleErrorResponse(error.response, "error");
    //     });
    // },
    fileUploaded(file) {
      this.ticket.attachment = file.xhr.response;
    },
    submitTicket() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.$axios
        .post("/ticket/store", {
          user_id: this.user.id ?? this.currentUser.id,
          name: this.user.name ?? this.currentUser.name,
          email: this.user.email ?? this.currentUser.email,
          topic: this.ticket.topic,
          detail: this.ticket.detail,
          status: "Open",
          priority: this.ticket.priority,
          attachment: this.ticket.attachment,
        })
        .then((response) => {
          let data = response.data;
          this.triggerSwal(data.message, "success");
          this.$router.push("/tickets");
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          this.processing = false;
        });
    },

    fetchAllMembers() {
      let url = "/employee/team-member";
      if (this.users.nextPageUrl) {
        url = this.users.nextPageUrl;
      }
      this.$axios
        .get(url, 
        // {params: { employee_status: "active_employee" }}
        )
        .then((response) => {
          let result = response.data.payload.paginated;
          if (url == "/employee/team-member") this.users.data = [];
          this.users.data.push(...result.data);
          this.users.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },
    fetchUsersOnReachingEnd() {
      this.fetchAllMembers();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <component :is="currentLayout">
    <PageHeader :title="title" />
    <div class="card border">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mb-0">
            <h2 class="f-22 fw-bold mb-0">Details</h2>
          </div>
        </div>
        <hr class="custom-divider" />
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="priority_id">Topic</label>
            <!-- <select class="form-select" v-model="ticket.topic" :class=" {
							'is-invalid' : $v.ticket.topic.$error}">
                           <option v-for="(topic,index) in topics" :key="index" :value="topic"> {{topic}}</option>
                        </select> -->
            <input
              class="form-control"
              v-model="ticket.topic"
              :class="{
                'is-invalid': $v.ticket.topic.$error,
              }"
              placeholder="Topic"
            />

            <div v-if="$v.ticket.topic.$error" class="invalid-feedback">
              <span v-if="!$v.ticket.topic.required">Topic is required</span>
            </div>
            <div v-if="$v.ticket.topic.$error" class="invalid-feedback">
              <span v-if="!$v.ticket.topic.maxLength"
                >Text should not be more than 50 character.</span
              >
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="priority_id">Priority</label>
            <select
              class="form-select"
              v-model="ticket.priority"
              :class="{
                'is-invalid': $v.ticket.priority.$error,
              }"
            >
              <option disabled value="" selected>Set Priority</option>
              <option value="Low">Low</option>
              <option value="Normal">Normal</option>
              <option value="High">High</option>
              <option value="Emergency">Emergency</option>
            </select>
            <div v-if="$v.ticket.priority.$error" class="invalid-feedback">
              <span v-if="!$v.ticket.priority.required"
                >Priority is required</span
              >
            </div>
          </div>
      
          <div v-if="!isTeamMember()">
            <div class="col-md-6 mb-3" >
              <div class="form-check form-switch mb-2" >
                <input
                  class="form-check-input"
                  v-model="referenceTicket"
                  type="checkbox" 
                  :value="true" 
                />
                <label class="form-check-label fw-normal" >
                  Creating a ticket for employee? Once a you create a reference ticket you will not be able to edit/delete ticket. </label>
              </div>
            </div>  
            <div class="col-md-6 mb-3" v-if="referenceTicket">
              <label for="priority_id">Employee</label>
                <vue-multiselect
                  placeholder="Select an Employee"
                  v-model="user"
                  :load-more="loadMoreUsers"
                  :loading="users.loading"
                  :options="users.data"
                  @on-open="fetchAllMembers"
                  @on-reaching-end="fetchUsersOnReachingEnd"
                  label="name"
                  internal-search
                  showNoResult
                >
                </vue-multiselect>
            </div>
          </div>
          
          <div class="col-md-12 mb-3">
            <label for="desc_id">Description</label>
            <textarea
              type="text"
              id="desc_id"
              class="form-control"
              placeholder="Description"
              rows="5"
              v-model="ticket.detail"
              :class="{
                'is-invalid': $v.ticket.detail.$error,
              }"
            >
            </textarea>
            <div v-if="$v.ticket.detail.$error" class="invalid-feedback">
              <span v-if="!$v.ticket.detail.required"
                >Description is required.</span
              >
            </div>
          </div>
          <div class="col-md-12">
            <label>Attachment (optional)</label>
            <vue-dropzone
              id="dropzone"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-success="fileUploaded"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </vue-dropzone>
          </div>
          <div class="col-md-12 text-end mt-3">
            <SubmitButton
              :processing="processing"
              type="submit"
              class="mx-2"
              text="Save"
              @clicked="submitTicket"
            />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>
